import React from 'react';

const IconAnte = (props) => {
    return (
        <svg version="1.1" className={props.className} id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 487 487" style={{ enableBackground: 'new 0 0 487 487' }} xmlSpace="preserve">
            <g>
                <path d="M299.5,432h-25.409c-2.784-11.354-11.737-20.306-23.091-23.091V206.091c13.759-3.374,24-15.806,24-30.591
		c0-17.369-14.131-31.5-31.5-31.5S212,158.131,212,175.5c0,14.785,10.241,27.216,24,30.591v202.819
		c-11.354,2.784-20.306,11.737-23.091,23.091H187.5c-12.958,0-23.5,10.542-23.5,23.5v24c0,4.142,3.358,7.5,7.5,7.5h144
		c4.142,0,7.5-3.358,7.5-7.5v-24C323,442.542,312.458,432,299.5,432z M227,175.5c0-9.098,7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5
		s-7.402,16.5-16.5,16.5S227,184.598,227,175.5z M243.5,423c6.396,0,11.942,3.666,14.679,9h-29.357
		C231.558,426.666,237.104,423,243.5,423z M308,472H179v-16.5c0-4.687,3.813-8.5,8.5-8.5h112c4.687,0,8.5,3.813,8.5,8.5V472z"/>
                <path d="M243.5,0C146.729,0,68,78.729,68,175.5c0,38.558,12.278,75.14,35.506,105.791c22.46,29.637,54.37,51.78,89.853,62.348
		c3.968,1.181,8.146-1.078,9.329-5.047c1.182-3.97-1.077-8.146-5.047-9.329c-32.448-9.665-61.633-29.918-82.181-57.032
		C94.225,244.209,83,210.76,83,175.5C83,87,155,15,243.5,15S404,87,404,175.5c0,35.26-11.225,68.709-32.46,96.731
		c-20.547,27.113-49.733,47.367-82.181,57.032c-3.97,1.183-6.229,5.359-5.047,9.329c0.97,3.255,3.953,5.361,7.185,5.361
		c0.708,0,1.43-0.102,2.144-0.314c35.483-10.568,67.393-32.711,89.853-62.348C406.722,250.64,419,214.058,419,175.5
		C419,78.729,340.271,0,243.5,0z"/>
                <path d="M243.5,111c35.565,0,64.5,28.935,64.5,64.5c0,18.23-7.839,35.716-21.507,47.975c-3.084,2.766-3.342,7.507-0.576,10.591
		c1.481,1.652,3.529,2.493,5.586,2.493c1.783,0,3.573-0.632,5.005-1.917C313.344,219.542,323,197.985,323,175.5
		c0-43.836-35.664-79.5-79.5-79.5S164,131.664,164,175.5c0,22.485,9.656,44.042,26.493,59.142c3.084,2.766,7.825,2.508,10.591-0.576
		c2.766-3.083,2.508-7.825-0.576-10.591C186.839,211.216,179,193.73,179,175.5C179,139.935,207.935,111,243.5,111z"/>
                <path d="M291.504,292.95c1.003,0,2.023-0.203,3.001-0.63C340.974,272.002,371,226.147,371,175.5C371,105.196,313.804,48,243.5,48
		S116,105.196,116,175.5c0,50.647,30.026,96.502,76.495,116.82c3.795,1.657,8.217-0.072,9.877-3.868
		c1.659-3.795-0.072-8.217-3.867-9.876C157.497,260.646,131,220.187,131,175.5C131,113.467,181.467,63,243.5,63
		S356,113.467,356,175.5c0,44.687-26.497,85.146-67.505,103.077c-3.795,1.659-5.526,6.081-3.867,9.876
		C285.86,291.271,288.614,292.95,291.504,292.95z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    );
};

export default IconAnte;