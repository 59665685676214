import React from 'react';

const IconSupport = (props) => {
    return (
        <svg version="1.1" className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.352 408.352" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ enableBackground: "new 0 0 408.352 408.352" }}>
            <path d="m408.346,163.059c0-71.92-54.61-131.589-125.445-138.512-2.565-8.81-10.698-15.272-20.325-15.272h-116.801c-9.627,0-17.759,6.462-20.324,15.272-70.837,6.932-125.451,66.602-125.451,138.512v111.142c0,23.601 19.201,42.802 42.801,42.802h33.32c9.916,0 17.983-8.067 17.983-17.983v-118.102c0-9.916-8.067-17.983-17.983-17.983h-33.32c-10.606,0-20.316,3.886-27.801,10.298v-10.174c-1.06581e-14-64.07 48.585-117.252 111.653-123.559 3.401,7.16 10.682,12.134 19.122,12.134h116.801c8.44,0 15.721-4.974 19.123-12.134 63.065,6.299 111.647,59.481 111.647,123.56v10.169c-7.485-6.409-17.193-10.294-27.796-10.294h-33.32c-9.916,0-17.983,8.067-17.983,17.983v118.101c0,9.916 8.067,17.983 17.983,17.983h33.32c10.606,0 20.316-3.886 27.802-10.299v5.459c0,28.339-23.056,51.395-51.395,51.395h-90.885c-3.288-11.818-14.14-20.518-26.991-20.518h-27.357c-15.449,0-28.018,12.569-28.018,28.018s12.569,28.018 28.018,28.018h27.357c12.851,0 23.703-8.7 26.991-20.518h90.885c36.61,0 66.395-29.784 66.395-66.395l-.006-149.103zm-329.241,17.859v118.101c-1.42109e-14,1.645-1.338,2.983-2.983,2.983h-2.983v-124.067h2.983c1.645,0 2.983,1.338 2.983,2.983zm-36.304-2.983h15.337v124.068h-15.337c-15.33,0-27.801-12.472-27.801-27.802v-68.465c-3.55271e-15-15.33 12.472-27.801 27.801-27.801zm219.775-141.302h-116.801c-3.407-7.10543e-15-6.179-2.772-6.179-6.179s2.772-6.179 6.179-6.179h116.801c3.407,0 6.18,2.772 6.18,6.179s-2.773,6.179-6.18,6.179zm66.67,262.386v-118.101c0-1.645 1.338-2.983 2.983-2.983h2.983v124.068h-2.983c-1.645,0-2.983-1.339-2.983-2.984zm-105.165,85.057h-27.357c-7.178,0-13.018-5.84-13.018-13.018s5.84-13.018 13.018-13.018h27.357c7.179,0 13.019,5.84 13.019,13.018s-5.84,13.018-13.019,13.018zm141.469-82.073h-15.337v-124.068h15.337c15.33,0 27.802,12.472 27.802,27.801v68.465c-5.68434e-14,15.33-12.472,27.802-27.802,27.802z" />
        </svg>
    );
};

export default IconSupport;