import React from 'react';

const NotFound = () => {
    return (
        <div>
            <img style={{ width: '50%' }} src="https://1080motion.com/wp-content/uploads/2018/06/NoImageFound.jpg.png" alt="" />
        </div>
    );
};

export default NotFound;